@font-face {
  font-family: 'AlphaHeadlineCE';
  src: url('../../assets/fonts/ahita___-webfont.woff2') format('woff2'),
       url('../../assets/fonts/ahita___-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Relevant';
  src: url('../../assets/fonts/Relevant-Normal.woff2') format('woff2'),
  url('../../assets/fonts/Relevant-Normal.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Relevant';
  src: url('../../assets/fonts/Relevant-Bold.woff2') format('woff2'),
  url('../../assets/fonts/Relevant-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Relevant';
  src: url('../../assets/fonts/Relevant-Light.woff2') format('woff2'),
  url('../../assets/fonts/Relevant-Light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  font-size: 20px;
}
body {
  background: white;
  margin: 0;
  padding: 0;
  font-family: 'Relevant', sans-serif;
  font-weight: 500;
}
h1, h2, h3 {
  font-family: 'AlphaHeadlineCE', sans-serif;
}

h2 {
  @apply text-3xl font-medium mt-5 mb-2.5;
}

h3 {
  @apply text-xl font-medium mt-5 mb-2.5
}
h4 {
  margin-bottom: 7.5px;
  font-weight: bold;
}
p{
  margin-bottom: 15px;
  white-space: pre-line;
}
/* Custom css */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* swpier stuff */
#info .swiper-pagination.swiper-pagination-horizontal {
  position: absolute;
  bottom: 26px;
  z-index: 90;
  left: 0;
  right: 0;
}
#story .swiper-pagination.swiper-pagination-horizontal {
  margin-top: 15px;
  margin-bottom: 35px;
}
.swiper-pagination.swiper-pagination-horizontal {
  height: 10px;
  display: flex;
  justify-content: center;
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #ABABAB;
  border-radius: 100%;
  margin: 0 5px;
}
#info .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: white;
}
#story .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: black
}
.data-sheet ul {
  list-style: disc;
  margin: 12px;
  padding: 12px;
}

ol {
  list-style-type: decimal;
  margin: 12px;
  padding: 12px;
}
